





































export default {
    props: ["data", "email", "domain"],
    computed: {
        accounts() {
            return this.data.user;
        }
        domainProductsList() {
            if (!this.data.domain) return [];
            else return Object.keys(this.data.domain);
        }
    }
}
