import Vuex from 'vuex';
import * as firebase from 'firebase';

export default new Vuex.Store({
    state: {
        user: undefined,
        loaded: false
    },
    getters: {
        isLoggedIn(state): boolean {
            return Boolean(state.user);
        }
    },
    mutations: {
        setUser(state, user?: firebase.User) {
            state.user = user;
        },
        setLoaded(state, value: boolean) {
            state.loaded = value;
        }
    },
    actions: {

    }
});
