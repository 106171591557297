













export default {
    props: ["users"],
    computed: {
        resultsFound() {
            return this.users.length;
        },
        resultsByService() {
            return this.users.reduce((acc, u) => {
                const services = Object.keys(u.services);
                for (const s of services) {
                    if (!acc[s]) acc[s] = 0;
                    acc[s]++;
                }
                return acc;
            }, {});
        }
    }
}
