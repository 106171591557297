export default class UserPreview {
    public email: string;
    public name?: string;
    public googleId?: string;
    public timezone?: string;
    public services: any;

    constructor(email: string, providers: any) {
        this.email = email;
        const addonData = this.getAddonData(providers.addon);
        const emData = this.getEmailmeterData(providers.emailmeter);
        const legacyData = this.getLegacyData(providers.legacy);

        this.name = emData.name || addonData.name || legacyData.name;
        this.googleId = emData.googleId || addonData.googleId;
        this.timezone = emData.timezone || legacyData.timezone;
        this.services = emData.services || {};
        if (addonData.googleId) {
            this.services.addon = { onboarded: true };
        }
        if (legacyData.userId) {
            this.services.legacy = {
                status: legacyData.status
            };
        }
    }

    private getAddonData(addon: any) {
        if (!addon || addon.length === 0) return {};
        return {
            name: addon[0].name,
            googleId: addon[0].googleId
        };
    }

    private getEmailmeterData(rawData: any) {
        if (!rawData) return {};

        return rawData.reduce((acc: any, account: any) => {
            if (!acc.name) acc.name = account.name;
            if (!acc.googleId) acc.googleId = account.googleUserId;
            if (!acc.services) acc.services = {};
            acc.services[account.product] = {
                onboarded: account.onboarded,
                onboardedAt: account.onboardedAt,
                clientId: account.clientId
            };
            if (!acc.timezone) acc.timezone = account.timezone;
            return acc;
        }, {});
    }

    private getLegacyData(rawData: any) {
        if (!rawData || rawData.length === 0) return {};
        const legacyData = rawData[0];
        return {
            name: `${legacyData.firstName} ${legacyData.lastName}`,
            userId: legacyData.userId,
            timezone: legacyData.timezone,
            status: legacyData.status
        };
    }
}
