import VueRouter from "vue-router";

// @ts-ignore
import DomainSearch from './pages/domain_search.vue';
// @ts-ignore
import Home from './pages/home.vue';

export default new VueRouter({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'home',
        component: Home,
    }, {
        path: '/search/:searchTag?',
        name: 'search',
        component: Home,
        props: true
    }
});
