









import Utils from '../utils';

import AddonProvider from './providers/addon.vue';
import EmailmeterProvider from './providers/emailmeter.vue';
import LegacyProvider from './providers/legacy.vue';

export default {
    props: ["provider", "data", "email", "domain"],
    components: {
        "addon-provider": AddonProvider,
        "emailmeter-provider": EmailmeterProvider,
        "legacy-provider": LegacyProvider
    }
}
