
export default class Utils {
    public static camel2Title(camelCase: string) {
        return camelCase.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase());
    }

    public static formatDate(date: Date | string): string {
        if (typeof date === 'string') date = new Date(date);
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        return year + " / " + String(month).padStart(2, "0") + " / " + String(day).padStart(2, "0");
    }

    public static isEmail(emailOrDomain: string): boolean {
        return emailOrDomain.indexOf("@") !== -1;
    }
}
