



















export default {
    props: ["data", "email", "domain"],
    computed: {
        user() {
            return this.data.user;
        }
        domainUsers() {
            if (!this.data.domain) return 0;
            return this.data.domain.users;
        }
    }
}
