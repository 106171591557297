













import Navbar from './components/navbar.vue';

export default {
    components: {
        "navbar": Navbar
    }
}
