import Vue from 'vue';
import VueRouter from "vue-router";
Vue.use(VueRouter);
import Vuex from 'vuex';
Vue.use(Vuex);
import FirebaseApi from "./api/firebase_api";
import router from './router';
import Utils from './utils';
import store from './store';
// @ts-ignore
import app from './index.vue';

import LittleBrotherApi from './api/little_brother_api';

Vue.prototype.$auth = FirebaseApi.auth;

Vue.filter('titlecase', (value: string) => {
    return Utils.camel2Title(value);
});
Vue.filter('formatDate', (value?: Date | string) => {
    if (!value) return "";
    return Utils.formatDate(value);
});

new Vue({ // tslint:disable-line
    el: '#app',
    store: store,
    router: router,
    render: h => h(app),
    beforeMount() {
        this.$auth.onAuthChange((user: firebase.User) => {
            if (user) {
                LittleBrotherApi.checkAuth().then((isAuth) => {
                    if (isAuth) {
                        this.$store.commit("setLoaded", true);
                        this.$store.commit("setUser", user || undefined);
                    } else {
                        console.error('Wrong domain')
                        this.$auth.logout();
                        this.$store.commit("setLoaded", true);
                        this.$store.commit("setUser", undefined);
                        this.$router.push("/");
                    }
                });
            } else {
                this.$store.commit("setLoaded", true);
                this.$store.commit("setUser", undefined);
                this.$router.push("/");
            }
        });
    }
});
