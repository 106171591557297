import FirebaseApi from './firebase_api';

export default class LittleBrotherApi {
    public static searchByEmail(email: string): any {
        return FirebaseApi.callFunction(`/email/${email}`);
    }

    public static searchByDomain(domain: string): any {
        return FirebaseApi.callFunction(`/domain/${domain}`);
    }

    public static async checkAuth(): Promise<boolean> {
        try {
            await FirebaseApi.callFunction(`/auth`);
            return true;
        } catch (err) {
            return false;
        }
    }
}
