

































import LittleBrotherApi from '../api/little_brother_api';
import ProviderInfoCard from '../components/provider_info_card.vue';
import UsersList from '../components/user_list.vue';
import DomainPreview from '../components/domain_preview';
import UserPreview from '../models/user_preview';
import Utils from '../utils';

export default {
    props: ["searchTag"]
    components: {
        "provider-info-card": ProviderInfoCard,
        "user-list": UsersList,
        "domain-preview": DomainPreview
    },
    data() {
        return {
            loading: false,
            searchText: "",
            errorInSearch: false,
            searchParam: "",
            result: null
        }
    },
    computed: {
        loggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        providers() {
            if (!this.result || !this.result.providers) return null;
            return Object.keys(this.result.providers).sort();
        },
        userResult() {
            return this.result && Utils.isEmail(this.searchParam);
        },
        domainResult() {
            return this.result && !Utils.isEmail(this.searchParam);
        },
        validSearch() {
            return this.searchText && this.searchText.length >= 3 && this.searchParam !== this.searchText;
        }
    },
    methods: {
        search() {
            if (!this.loading && this.validSearch) {
                this.searchParam = this.searchText.trim().toLowerCase();
                this.errorInSearch = false;
                this.loading = true;
                let resultPromise;
                let isEmail = Utils.isEmail(this.searchParam);
                if (isEmail) {
                    resultPromise = this._searchByEmail();
                } else {
                    resultPromise = this._searchByDomain();
                }
                return resultPromise.then((res) => {
                    this.result = res;
                }).catch((err) => {
                    this.errorInSearch = true;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        updateSearchUrl() {
            if (this.validSearch)
                this.$router.push(`/search/${encodeURI(this.searchText.trim().toLowerCase())}`)
        },
        login() {
            this.$auth.login().then((res) => {
                this.$router.push("/")
            }).catch((error) => {
                console.error("Error on login", error.code, error.message);
            });
        },
        clearResult() {
            this.result = null;
            this.searchParam = null;
        },
        _searchByEmail() {
            return LittleBrotherApi.searchByEmail(encodeURI(this.searchText))
        },
        _searchByDomain() {
            return LittleBrotherApi.searchByDomain(encodeURI(this.searchText)).then((res) => {
                return this._processDomainResult(res);
            });
        },
        _processDomainResult(res) {
            const usersByEmail = {};
            const providersList = Object.keys(res.providers);
            for (const p of providersList) {
                const provider = res.providers[p];
                for (const user of provider) {
                    const email = user.email;
                    if (!email) console.error("Email not found", email);
                    if (!usersByEmail[email]) usersByEmail[email] = {
                        email: email,
                        providers: {}
                    };
                    if (!usersByEmail[email].providers[p]) usersByEmail[email].providers[p] = [];
                    usersByEmail[email].providers[p].push(user)
                }
            }
            return Object.keys(usersByEmail).map((e) => {
                const user = usersByEmail[e];
                return new UserPreview(user.email, user.providers);
            });
        }
    },
    watch: {
        searchTag: {
            immediate: true,
            handler(newVal) {
                this.clearResult();
                if (newVal) { this.searchText = newVal.trim().toLowerCase(); }
                this.search();
            }
        }
    }
}
