"use strict";
import * as firebase from 'firebase';
import Vue from 'vue';

export default class AuthApi {
    private auth: firebase.auth.Auth;
    constructor(auth: firebase.auth.Auth) {
        this.auth = auth;
    }

    public get user(): firebase.User {
        return this.auth.currentUser;
    }

    public onAuthChange(cb: () => void) {
        this.auth.onAuthStateChanged(cb);
    }

    public login(): Promise<firebase.auth.UserCredential> {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.auth.signInWithPopup(provider);
    }

    public logout(): Promise<void> {
        return this.auth.signOut();
    }
}
