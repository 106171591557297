"use strict";

import * as firebase from 'firebase';
import AuthApi from './auth_api';

const config = {
    apiKey: "AIzaSyBc3cSz_inNzZHY4wt6H9wGDdsTXNFQk44",
    authDomain: "little-brother-fe0bc.firebaseapp.com",
    databaseURL: "https://little-brother-fe0bc.firebaseio.com",
    projectId: "little-brother-fe0bc",
    storageBucket: "little-brother-fe0bc.appspot.com",
    messagingSenderId: "53484426761"
};

const API_URL = process.env.CLOUD_FUNCTIONS_URL;

class FirebaseApi {
    public auth: AuthApi;
    public db: firebase.firestore.Firestore;
    public functions: firebase.functions.Functions;

    constructor() {
        firebase.initializeApp(config);
        this.auth = new AuthApi(firebase.auth());
        this.db = firebase.firestore();
        const dbSettings = {};
        this.db.settings(dbSettings);
        this.functions = firebase.functions();
    }

    public async callFunction(url: string, method = "GET", data?: any): Promise<any> {
        if (!data) data = undefined;
        else data = JSON.stringify(data);
        const token = await this.auth.user.getIdToken();
        const result = await fetch(API_URL + url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: "cors", // no-cors, cors, *same-origin
            method: method,
            body: data
        });
        if (!result.ok) {
            console.error(await result.json());
            return Promise.reject(new Error(`Error on request to ${url}`));
        }
        return await result.json();
    }
}

export default new FirebaseApi();
