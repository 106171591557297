

















export default {
    computed: {
        loggedIn() {
            return this.$store.getters.isLoggedIn;
        }
    },
    methods: {
        login() {
            this.$auth.login().then((res) => {
                this.$router.push("/")
            }).catch((error) => {
                console.error("Error on login", error.code, error.message);
            });
        },
        logout() {
            this.$auth.logout();
        }
    }
}
