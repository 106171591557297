

































export default {
    props: ["users"],
    data() {
        return {
            filters: {
                name: "",
                service: "",
                email: "",
                timezone: ""
            },
            sortBy: "email",
            ascOrder: false
        }
    },
    computed: {
        usersList() {
            const sortBy = this.sortBy;
            return this.users.filter((u) => {
                return this.matchSearch(u.name, this.filters.name) &&
                    this.matchSearch(u.email, this.filters.email) &&
                    this.matchSearch(Object.keys(u.services).join(" "), this.filters.service) &&
                    this.matchSearch(u.timezone, this.filters.timezone);
            }).sort((a, b) => {
                let val = 0
                if (a[sortBy] > b[sortBy]) val = 1;
                else if (a[sortBy] < b[sortBy]) val = -1;
                if (this.ascOrder) val = -val
                return val;
            })
        },
        orderCharacter() {
            if (this.ascOrder) return "↑";
            else return "↓";
        }
    },
    methods: {
        parseServices(services) {
            const services = Object.keys(services).sort().join("<br/>");
            return services
        },
        matchSearch(string, search) {
            if (!search) return true;
            if (!string) return false;
            return string.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
        },
        sortByField(sortField) {
            if (sortField === this.sortBy) {
                this.ascOrder = !this.ascOrder;
            } else {
                this.sortBy = sortField;
                this.ascOrder = false;
            }
        }
    }
}
