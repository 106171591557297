


























export default {
    props: ["data", "email", "domain"],
    computed: {
        account() {
            return this.data.user;
        },
        domainUsers() {
            if (!this.data.domain) return null;
            else return this.data.domain.users
        }
    }
}
